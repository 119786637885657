
import { LoginDialog, tools } from '@wenjuan/business'
import localKeys from '~/utils/local-keys/index.js'

const { cacheLoginOnce: cacheLoginOnceKey } = localKeys
const {
  useHooks: { useLocal },
} = tools
export default {
  descript: '新版登入注册弹窗',
  name: 'NewLoginDialog',
  components: {
    LoginDialog,
  },
  data() {
    return {
      cacheLoginOnce: null,
    }
  },
  computed: {
    loginStatus() {
      return { ...this.$store.state.loginStatus, ...this.$attrs }
    },
  },
  mounted() {
    this.onceDayComfirm()
  },

  methods: {
    handleClose() {
      this.$store.commit('setLoginStatus', { show: false, nuxtUrl: '/list' })
      this.$emit('close')
    },
    // 一天一次
    onceDayComfirm() {
      const isMobile = this.$store.state.mediaType.isMobile
      if (isMobile) return
      // 模糊列表白名单
      const pathList = [
        '/survey',
        '/satisfaction',
        '/assess',
        '/appraise',
        '/knowledge',
        '/app',
        '/collect_post',
        '/pro',
        '/senior_user',
        '/lib/industry',
        '/lib/scene',
        '/lib/tags',
        '/lib/search',
        '/lib/label',
        '/lib_detail_full',
        '/topic',
      ]
      // 精准列表白名单
      const exactList = ['/', '/lib', '/lib/']
      this.cacheLoginOnce = useLocal(cacheLoginOnceKey, 0)
      const { fullPath, path } = this.$route
      const currentDate = new Date().getDate()
      if (
        (!pathList.some((p) => path.startsWith(p)) && !exactList.includes(path)) ||
        currentDate === this.cacheLoginOnce.value
      )
        return
      window.onload = () => {
        if (this.loginStatus.loggedIn === 1) return
        setTimeout(() => {
          // 以下开始进入弹窗
          this.cacheLoginOnce.value = currentDate
          this.$store.commit('setLoginStatus', { show: true, nuxtUrl: fullPath })
        }, 1000)
      }
    },
  },
}
